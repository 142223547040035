<template>
    <section id="coupon" class="trunk">
        <pageCtrl v-model:lang="curLang" :page="'/immigrun/coupon'" @change="cnDisp = curLang.includes('cn')" :cloak="cloak" />

        <div v-if="curLang === 'en'">
            <h2>Welcome Coupons</h2>
            <p>
                These welcome gift coupons are an exclusive benefit for new immigrants. You are invited to request one
                and have it sent to your email. The information you provide will be verified on-site when you use the coupon.
            </p>
        </div>
        <div v-if="curLang === 'fr'">
            <h2>Coupons de bienvenue</h2>
            <p>
                Ces coupons de bienvenue sont un avantage exclusif pour les nouveaux immigrants. Vous êtes invité à en
                demander un et à le recevoir par e-mail. Les informations que vous fournissez seront vérifiées sur place
                lors de l'utilisation du coupon.
            </p>
        </div>
        <div v-if="curLang === 'cn'" class="lang-cn">
            <h2>新移民专享礼券</h2>
            <p>
                这些欢迎代金券是新移民的专属福利。欢迎申请并将其投递到您的电子邮箱。您提供的信息需要在使用优惠券的时候现场核实。
            </p>
        </div>
        <div v-if="curLang === 'tcn'" class="lang-cn">
            <h2>歡迎禮券</h2>
            <p>
                這些歡迎禮券是新移民的專屬福利。您可以申請一張並將其發送到您的電子郵件。您提供的資訊將在使用禮券時現場核實。
            </p>
        </div>
        <div v-if="curLang === 'kor'">
            <h2>환영 쿠폰</h2>
            <p>
                이 환영 쿠폰은 신입 이민자를 위한 독점 혜택입니다. 쿠폰을 요청하여 이메일로 받아보세요. 제공하신 정보는 쿠폰 사용 시 현장에서 확인됩니다.
            </p>
        </div>
        <div v-if="curLang === 'es'">
            <h2>Cupones de Bienvenida</h2>
            <p>
                Estos cupones de bienvenida son un beneficio exclusivo para nuevos inmigrantes. Estás invitado a
                solicitar uno y recibirlo por correo electrónico. La información que proporciones será verificada en el
                sitio al usar el cupón.
            </p>
        </div>
        <div v-if="curLang === 'pt'">
            <h2>Cupons de Boas-Vindas</h2>
            <p>
                Estes cupons de boas-vindas são um benefício exclusivo para novos imigrantes. Você está convidado a
                solicitar um e recebê-lo por e-mail. As informações fornecidas serão verificadas no local ao usar o
                cupom.
            </p>
        </div>
        <div v-if="curLang === 'far'" class="lang-far">
            <h2>کوپن‌های خوش‌آمدگویی</h2>
            <p>
                این کوپن‌های خوش‌آمدگویی یک مزیت انحصاری برای مهاجران جدید است. شما دعوت می‌شوید تا یکی از آنها را
                درخواست داده و به ایمیل خود ارسال کنید. اطلاعات ارائه‌شده شما هنگام استفاده از کوپن در محل تأیید خواهد
                شد.
            </p>
        </div>

        <div v-if="cpcode" class="form-wrap form-snap">
            <div v-if="httpStatus >= 200 && httpStatus <= 299">
                <p>
                    <label>Coupon No.:</label> <span class="input-full">{{ agent.index }}</span>
                </p>
                <p>
                    <label>Name:</label> <span class="input-full">{{ agent.name }}</span>
                </p>
                <p>
                    <label>Status:</label> <span class="input-full">{{ agent.status }}</span>
                </p>
                <p>
                    <label>ID# Suffix:</label> <span class="input-full">{{ agent.suffix }}</span>
                </p>
                <p>
                    <label>Origin:</label> <span class="input-full">{{ agent.origin }}</span>
                </p>
                <p>
                    <label>Landing Date:</label> <span class="input-full">{{ agent.landing }}</span>
                </p>
                <p>
                    <label>Phone#:</label> <span class="input-full">{{ agent.phone }}</span>
                </p>
                <p>
                    <label>Email:</label> <span class="input-full">{{ agent.email }}</span>
                </p>
                <p>
                    <label>Expiry:</label> <span class="input-full">{{ agent.expiry.split('T')[0] }}</span>
                </p>
                <p v-if="submitting >= 200 && submitting <= 299" style="margin-top: 32px;">
                    &emsp; <i class="fa fa-exclamation-triangle" style="color: orange; font-size: x-large;"></i>
                    &emsp; <span style="text-decoration: underline; font-size: large;">This coupon is now expired.</span>
                </p>
                <div class="btn-container">
                    <input type="text" v-model="expireNote" :disabled="submitting" placeholder="Store#, clerk name, or any note" style="height: 24px; margin-right: 10px; flex: 65%;" />
                    <button :disabled="submitting" @click="expireIt" style="flex: 35%;">Expire It</button>
                </div>
            </div>
            <p v-else-if="httpStatus === 410" style="font-size: x-large;">
                This coupon is used or expired on {{ agent.expiry.split('T')[0] }}.
            </p>
            <p v-else-if="httpStatus >= 400 && httpStatus <= 499" style="font-size: x-large;">
                The URL is mis-spelled.
            </p>
            <p v-else-if="httpStatus >= 500">
                We encountered an unexpected issue with the application. Please contact us at info@ai-directly.com
                or try again later. We apologize for the inconvenience.
            </p>
        </div>
        <div v-else class="form-wrap">
            <!-- Source document: https://ismail9k.github.io/vue3-carousel/ -->
            <Carousel :items-to-show="1" :snap-align="'center'" :gap="20" :wrap-around="true" :autoplay="autoCarousel" :pause-autoplay-on-hover="true">
                <Slide v-for="coupon in coupons" :key="coupon.id" class="carousel-slide" @click="autoCarousel = false; bizid = coupon.id; bizname = coupon.bizname;">
                    <div class="slide-images">
                        <img :src="`../img/coupon/${coupon.face}`" class="coupon-img" />
                        <img :src="`../img/coupon/${coupon.back}`" class="coupon-img" />
                    </div>
                    <h4 :class="{ 'slide-highlight': coupon.id === bizid }">{{ coupon.bizname }}</h4>
                </Slide>

                <template #addons>
                    <Navigation>
                        <!--template #prev>
                            <div class="circle-button">‹</div>
                        </template>
                        <template #next>
                            <div class="circle-button">›</div>
                        </template-->
                    </Navigation>
                    <Pagination />
                </template>
            </Carousel>

            <form @submit.prevent="submitForm">
                <p>
                    <label>
                        <span v-if="!cnDisp">*Name:</span>
                        <span v-else class="lang-cn">*姓名:</span>
                    </label>
                    <input class="input-full" type="text" v-model="agent.name" placeholder="Full name like Mr. John Doe" required />
                </p>
                <p>
                    <label>
                        <span v-if="!cnDisp">*Status:</span>
                        <span v-else class="lang-cn">*身份:</span>
                    </label>
                    <select v-model="agent.status" required :disabled="!bizid" :style="{ color: (agent.status) ? 'inherit' : pholderGray }">
                        <option value="" disabled selected>
                            Choose a coupon to enable this list
                        </option>
                        <option value="Permanent Resident" style="color: #1a1f26;">
                            <span v-if="!cnDisp">Permanent Resident</span>
                            <span v-else class="lang-cn">永久居民</span>
                        </option>
                        <option value="Refugee and Asylum" v-if="coupons.find(coupon => coupon.id === bizid)?.for_refugee" style="color: #1a1f26;">
                            <span v-if="!cnDisp">Refugee and Asylum</span>
                            <span v-else class="lang-cn">难民</span>
                        </option>
                        <option value="International Student" v-if="coupons.find(coupon => coupon.id === bizid)?.for_student" style="color: #1a1f26;">
                            <span v-if="!cnDisp">International Student</span>
                            <span v-else class="lang-cn">国际留学生</span>
                        </option>
                        <option value="Other Newcomer" v-if="coupons.find(coupon => coupon.id === bizid)?.for_other" style="color: #1a1f26;">
                            <span v-if="!cnDisp">Other Newcomer</span>
                            <span v-else class="lang-cn">其他新来者</span>
                        </option>
                    </select>
                </p>
                <p>
                    <label>
                        <span v-if="!cnDisp">*ID# Suffix:</span>
                        <span v-else class="lang-cn">*证件尾号:</span>
                    </label>
                    <input class="input-full" type="number" v-model="agent.suffix" placeholder="Last 3 digits of Landing ID" required />
                </p>
                <p>
                    <label>
                        <span v-if="!cnDisp">*Origin:</span>
                        <span v-else class="lang-cn">*来源国:</span>
                    </label>
                    <input class="input-full" type="text" v-model="agent.origin" placeholder="Country of origin" required />
                </p>
                <p>
                    <label>
                        <span v-if="!cnDisp">*Landing Date:</span>
                        <span v-else class="lang-cn">*登陆日期:</span>
                    </label>
                    <input class="input-full" type="date" v-model="agent.landing" required placeholder="yyyy-mm-dd" :style="{ color: (agent.landing) ? 'inherit' : pholderGray }" />
                </p>
                <p>
                    <label>
                        <span v-if="!cnDisp">*Phone#:</span>
                        <span v-else class="lang-cn">*手机:</span>
                    </label>
                    <!--input class="input-full" type="tel" v-model="agent.phone" pattern="\+[0-9]{1,3}[-\s]?[(]?[0-9]{1,5}[)]?[-\s]?[0-9]{3,4}[-\s]?[0-9]{4}" placeholder="Phone# like +1 (416) 123-4567" required /-->
                    <input class="input-full" type="tel" v-model="agent.phone" placeholder="Cellphone" required />
                </p>
                <p>
                    <label>
                        <span v-if="!cnDisp">*Email:</span>
                        <span v-else class="lang-cn">*电邮:</span>
                    </label>
                    <input id="email-input" class="input-full" type="email" v-model="agent.email" placeholder="Personal email" required />
                </p>
                <p>
                    <label>
                        <span v-if="cnDisp" class="lang-cn">*验证码:</span>
                        <span v-else>*Passcode:</span>
                    </label>
                    <span class="input-full">
                        <input type="number" v-model="agent.ecode" placeholder="through email" required />
                        <button type="button" @click="requestPasscode('ImmigRun', agent.email)">
                            <span v-if="cnDisp">获取验证码</span>
                            <span v-else>Request Passcode</span>
                        </button>
                    </span>
                </p>
                <div style="margin-top: 32px; font-size: large;">
                    <p v-if="submitting === -1" style="justify-content: center;">
                        <img src="@/assets/spinnerBlue.gif" alt="Wait..." style="width: 80px" />
                    </p>
                    <p v-if="httpStatus === 200 || httpStatus === 202">
                        &emsp; <i class="fa fa-check" style="color: green; font-size: x-large;"></i>
                        &emsp; <span style="text-decoration: underline;">Your submission is successfully accepted and the coupon (#{{ agent.index }}) will be emailed to you shortly.</span>
                    </p>
                    <p v-else-if="httpStatus === 404">
                        &emsp; <i class="fa fa-exclamation-triangle" style="color: orange; font-size: x-large;"></i>
                        &emsp; <span style="text-decoration: underline;">All coupons of {{ bizname }} have expired on {{ agent.expiry }}.</span>
                    </p>
                    <p v-else-if="httpStatus === 409">
                        &emsp; <i class="fa fa-ban" style="color: red; font-size: x-large;"></i>
                        &emsp; <span style="text-decoration: underline;">You're already assigned a coupon of {{ bizname }}, numbered {{ agent.index }}.</span>
                    </p>
                    <p v-else-if="httpStatus === 410">
                        &emsp; <i class="fa fa-exclamation-triangle" style="color: orange; font-size: x-large;"></i>
                        &emsp; <span style="text-decoration: underline;">All coupons of {{ bizname }} are gone.</span>
                    </p>
                </div>
                <div class="btn-container">
                    <button :disabled="!!submitting" type="submit">
                        <span v-if="!cnDisp">Request Coupon</span>
                        <span v-else>领 取 优 惠 券</span>
                        <span v-show="submitting > 0"> ({{ submitting }})</span>
                    </button>
                </div>
            </form>
        </div>
    </section>
</template>

<script>
import "vue3-carousel/dist/carousel.css";       // source: https://ismail9k.github.io/vue3-carousel/
import { Carousel, Slide, Navigation, Pagination } from 'vue3-carousel';
import { ref, reactive, onMounted } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';
import pageCtrl from "../components/pageCtrl.vue";
import { requestPasscode } from "../util/common.js";

export default {
    name: 'CouponForm',
    components: {
        pageCtrl,
        Carousel,
        Slide,
        Navigation,
        Pagination
    },
    setup() {
        // initialize the display language and whether the page be on post
        // The language display is pretty complicated
        // 1. Display in languages is controlled by variable curLang and v-if
        // 2. if the language is cn or tcn, the CSS will let the space between characters be wider through the class lang-cn
        // 3. if the language is far, the CSS wil let the font be larger through the class lang-far
        // 4. if the language is cn or tcn, labels and buttons will be shown in Chinese due to variable cnDisp and v-if, while cnDisp is set by @change script in <select>
        const queryParams = new URLSearchParams(window.location.search);
        const bizid = ref(queryParams.get('bizid'));
        const cpcode = queryParams.get('cpcode');
        const curLang = ref(queryParams.get('lang') || 'en');
        const cnDisp = ref(curLang.value.includes('cn'));

        // The 'cloak' is specifically for accommodating immigrun.com to let home <button> to hide
        const cloak = (window.self !== window.top);

        //
        const coupons = [
                { id: "casaMartian", bizname: "CasaMartian Smart Home", face: "casamartian.face.png", back: "casamartian.back.png", city: "Toronto", state: "ON", country: "Canada", for_refugee: true, for_other: true },
                { id: "mapleMattress", bizname: "Maple Mattress", face: "maple_mattress.face.png", back: "maple_mattress.back.png", city: "Toronto", state: "ON", country: "Canada", for_refugee: true, for_student: true, for_other: true },
        ];

        const bizname = ref(coupons.find(cp => cp.id === bizid.value)?.bizname);

        const agent = reactive({
            index: 0,
            name: '',
            status: '',
            suffix: '',     // last 3 digits of ID#
            origin: '',     // country of oringin
            landing: (new Date()).toISOString().split('T')[0],    // landing date
            phone: '',
            email: '',
            ecode: '',      // email passcode, potentially there could be a SMS passcode called tcode
            expiry: ''
        });

        const expireNote = ref('');
        const submitting = ref(0);
        const httpStatus = ref(0);
        const autoCarousel = ref(3000);
        const pholderGray = (window.innerWidth > 1023) ? 'gray' : '#a8a8a8';

        // Section A: for businesses to verify a coupon
        if (cpcode) {
            if (!bizid.value) {
                httpStatus.value = 400;
                return;
            }

            fetch(`${process.env.VUE_APP_API_URL}/chat/couponver`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ coid: 'ImmigRun', bizid: bizid.value, cpcode })
            })
            .then(response => {
                httpStatus.value = response.status;
                return response.json();         // Return the parsed JSON Promise
            })
            .then(data => {
                if (httpStatus.value >= 200 && httpStatus.value <= 299) {
                    for (const key in agent) {
                        agent[key] = data[key] || '';
                    }
                } else {              // This tends to handle HTTP status codes that are in 400 and 500 families, but may also include that in the uncommon 100 and 300 families
                    if (httpStatus.value === 410) {
                        agent.expiry = data.expiry;
                    }
                }
            })
            .catch(error => {       // catches the error from 'fetch'
                alert(`CP11: ${error.name}\n\nSubmission failed due to unexpected error. Please try again later.`);
            });
        }

        const expireIt = () => {
            if (submitting.value) {    // because the server response takes time, multi submissions during this period shalll be prevented
                return;
            } else {
                submitting.value = -1;
            }

            fetch(`${process.env.VUE_APP_API_URL}/chat/couponver`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ coid: 'ImmigRun', bizid: bizid.value, cpcode, expiry_note: expireNote.value || 'ExpireIt' })        // set expiry in Boolean for better instruction, it still instructs
            })
            .then(response => {
                // Don't assign value to 'httpStatus', which is for controlling the initial round of interrolgating
                // Use submitting to control second round of interrolgation process, i.e. ExpireIt
                if (response.ok) {
                    submitting.value = response.status;
                    return response.json();         // Return the parsed JSON Promise
                } else {     // all 5xx errors, impossible of 4xx error unless hacking
                    const msg = `Action failed due to a server error. Please contact us at info@ai-directly.com or try again later. We apologize for the inconvenience.`;
                    alert(`ExpIt01: ${response.status} - ${response.statusText}\n\n${msg}`);
                }
            })
            .then(data => {
                if (data) {
                    agent.expiry = data.expiry;
                }
            })
            .catch(error => {       // catches the error from 'fetch'
                alert(`ExpIt02: ${error.name}\n\nAction failed due to network errors. Please try again later.`);
            });
        };

        // Section B: for indiviguals to apply for a coupon
        const submitForm = () => {
            if (submitting.value) {    // because the server response takes time, multi submissions during this period shalll be prevented
                return;
            } else {
                submitting.value = -1;
            }

            // validate bizid
            if (!bizid.value) {
                alert('Please pick and click a coupon.');
                submitting.value = 0;
                return; 
            }

            // validate landing date that shall be within one year
            const timestamp = new Date();
            if (agent.landing < `${timestamp.getFullYear() - 1}-${(timestamp.getMonth() + 100).toString().slice(1)}-01`) {
                alert('The coupons are intended for newcomers who landed within one year.');
                submitting.value = 0;
                return; 
            }

            // proceed to the server
            fetch(`${process.env.VUE_APP_API_URL}/chat/couponform`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ coid: 'ImmigRun', bizid: bizid.value, ...agent })
            })
            .then(response => {
                httpStatus.value = response.status;
                return response.json();         // Return the parsed JSON Promise
            })
            .then(data => {
                if (httpStatus.value >= 200 && httpStatus.value <= 299) {
                    agent.index = data.index;

                    submitting.value = 15;      // for count-down of 15 seconds. Delay 15 seconds.
                    const intervalId = setInterval(() => {
                        if (--submitting.value < 1) {       // until it's 0
                            clearInterval(intervalId);
                        }
                    }, 1000);

                } else {        // for 4xx and 5xx codes, but may also include that in the uncommon 100 and 300 families
                    switch (httpStatus.value) {
                        case 400: {
                            alert(`CP21: Submission failed due to improper data.`);
                            break;
                        }
                        case 401: {     // 401 - Unauthorized, passcode failed
                            alert(`CP22: The email and passcode failed verification.`);
                            break;
                        }
                        case 403: {     // 403 - Forbidden, the landing date is disqualified
                            // prevented by the code above above
                            break;
                        }
                        case 404: {     // 404 Not Found, all coupons are expired
                            agent.expiry = data.expiry;
                            break;
                        }
                        case 409: {     // 409 Conflict, the coupon has already once assigned
                            agent.index = data.index;
                            break;
                        }
                        case 410: {     // 410 Gone, all coupons are gone
                            break;
                        }
                        default: {
                            const msg = `Submission failed due to server error or unexpected issue. Please inform info@ai-directly.com or try it later.`;
                            alert(`CP23: ${httpStatus.value}\n\n${msg}`);
                        }
                    }
                    submitting.value = 0;
                }
            })
            .catch(error => {       // catches the error from 'fetch'. This is the local error.
                alert(`CP24: Submission failed due to network error. Please try again later.\n\n${error.name}\n${error.message}\n\n${error.stack}`);
                submitting.value = 0;
            });
        };

        // to do when mounting
        onMounted(() => {
            document.getElementById('gpt-icon').style.display = 'none';
            document.getElementById('gpt-box').style.display = 'none';
            document.getElementById('about-us').style.display = 'none';
        });

        // to do at leaving
        onBeforeRouteLeave((to, from, next) => {
            document.getElementById('gpt-icon').style.display = 'block';
            document.getElementById('about-us').style.display = 'block';
            next();
        });

        return { bizid, bizname, cpcode, coupons, agent, expireNote, expireIt, curLang, cnDisp, cloak, autoCarousel, pholderGray, submitting, httpStatus, requestPasscode, submitForm };
    },
}
</script>

<style scoped>
h2 {
    margin-top: 16px;
}

/********** */
/* Carousel */
/********** */
h4 {
    margin: 16px auto 36px auto;
}

.carousel-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    cursor: pointer;
}

.slide-images {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;        /* Allows images to stack if space is insufficient */
}

.coupon-img {
    max-width: 80%;
    max-height: 2in;
    margin: 4px;
    object-fit: contain;
}

.slide-highlight {
    background: linear-gradient(to bottom, #FFF5EE, #FFE5D4); /* Light peach (#FFF5EE) to soft peach (#FFE5D4) */
    color: #1A1A1A; /* Dark text for readability */
    padding: 5px 16px;
    border-radius: 5px; /* Rounded corners for a smooth look */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    font-size: large;
}


/********* */
/* Form UI */
/********* */
.form-wrap {
    width: 100%;
    max-width: 1024px;
    margin: 40px auto;
    text-align: center;
}

form {
    display: inline-block;
    width: 100%;
    padding: 16px 0;
    justify-content: center;
}

.form-snap p,
form p {
    display: flex;    /* Enables flexbox */
    align-items: center;    /* Centers items vertically */
    width: 100%;
}

.form-snap span {
    color: #004b7a;
}

label {
    flex: 24%;    /* Does not grow, does not shrink, takes up 20% of the space */
    padding-right: 16px;
    text-align: right;
}

select,
input {
    padding: 15px 12px 13px 12px;
    border: 1px solid lightgray;
    border-radius: 3px;
    font-size: inherit;
}

select {
    flex: 79%;
    color: gray;
}

input[type="date"] {
    padding: 12px;
    font-family: inherit;
}

input:focus {
    outline: none;
    border-color: #004b7ae0;
}

.input-full {
    flex: 76%;    /* Takes the remaining space from <label> */
}

span.input-full {
    flex: 79%;
    display: inline-flex;
}

.input-full input {
    flex: 60%;    /* Takes the partial space within .input-full */
    margin: 0 12px 0 0;
}

.input-full button {
    flex: 40%;
    background-color: #fbf0e6;
    border: 1px solid lightgray;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(64, 64, 64, 0.1);
    transition: all 0.2s ease;
    font-family: inherit;
    font-size: inherit;
    cursor: pointer;
}

.input-full button:hover {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
}

.input-full button:active {
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.3);
    transform: translateY(2px);
    background-color: #f0e0d0;    /* Slightly darker shade when pressed */
}

.btn-container {
    display: flex;
    justify-content: center;
    margin-top: 40px;
}

.btn-container button {
    width: 38%;
    padding: 13px 10px 11px 10px;
    border-color: gray;
    border-radius: 5px;
    box-shadow: 0px 4px 10px rgba(64, 64, 64, 0.1);
    transition: all 0.3s ease;
    background: #004b7ae0;
    color: white;
    cursor: pointer;
    font-size: inherit;
    font-weight: bold;
    place-items: center;
}

.btn-container button:hover {
    border-color: lightgray;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    background: linear-gradient(135deg, #004b7ad0, #004b7af0);
}

.btn-container button:disabled {
    cursor: not-allowed;
}

@media screen and (max-width: 1023px) {
    label {
        flex: 34%;
        padding-right: 6px;
    }

    select,
    input {
        padding: 10px;
    }

    select {
        flex: none;
        max-width: 67%;
        background-color: white;
        background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"%3E%3Cpolygon points="0,0 20,0 10,10" fill="gray"%3E%3C/polygon%3E%3C/svg%3E');
        background-repeat: no-repeat;
        background-position: right 10px center;
        background-size: 10px;
    }

    input[type="date"] {
        height: 20px;
        padding: 10px;
        background-color: white;
        background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"%3E%3Cpolygon points="0,0 20,0 10,10" fill="gray"%3E%3C/polygon%3E%3C/svg%3E');
        background-repeat: no-repeat;
        background-position: right 10px center;
        background-size: 10px;
    }

    .input-full {
        flex: 66%;
    }

    span.input-full {
        flex: 72.3%;
    }

    .input-full input {
        flex: 48%;
        width: 40px;        /* to counter offset VUE3 default */
        min-width: 40px;        /* to counter offset VUE3 default */
        margin-right: 8px;
    }

    .input-full button {
        flex: 52%;
        font-family: Verdana, sans-serif;
        font-size: smaller;
    }

    .btn-container button {
        width: 68%;
        padding: 10px;
    }
}


/* ************************************************************************************ */
/* below is the setting to take off the incremental/incremental spin from Number Input
/* For WebKit browsers (Chrome, Safari, etc.) */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
</style>
