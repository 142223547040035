<template>
    <section id="career" class="trunk">
        <h2>Job Opennings</h2>
        <h3>Affiliate Account Developer</h3>
        <p>
            AI Directly is a start-up company at the forefront of AI applications. Leveraging our proprietary SaaS products, FirmGPT and TextingGPT, we help clients integrate AI, typically ChatGPT, into their websites, mobile apps, and other software systems. In the second tier of our product line, we offer an AI-powered immigration service platform that assists newcomers with settlement inquiries, job finding, and business planning. For details, please visit our websites at <a href="#">ai-directly.com/career</a> and <a href="https://immigrun.com" target="_blank">immigrun.com</a>.
        </p>
        <p>
            We are looking for <b>Affiliate Account Developer</b> or <b>Affiliate Business Consultant</b> who hold burning desires for success, through helping people excel with artificial intelligence.
        </p>
        <h4>Responsibilities:</h4>
        <ul>
            <li>Discover and engage leads to market FirmGPT through cold calls and emails;</li>
            <li>Promote the AI-powered immigration platform to communities of new immigrants, refugees, and international students through outreach efforts like distributing flyers and giving seminars;</li>
            <li>Solicit advertising and coupon distribution services to small businesses through site visits and cold calls;</li>
            <li>Follow up on proposals and close deals with support from our technical team;</li>
            <li>Maintain the accounts you develop and deliver consistent after-sales service.</li>
        </ul>
        <h4>Qualifications:</h4>
        <ul>
            <li>A college diploma or equivalent, preferably in technology or science fields, with the ability to grasp AI concepts at a business level;</li>
            <li>Prior experience in canvassing and cold calling is preferred;</li>
            <li>Excellent communication and interpersonal skills are essential;</li>
            <li>Resilient, determined, and perseverant in the face of challenges and rejection;</li>
            <li>Connections to ethnic communities of newcomers are a significant advantage.</li>
        </ul>
        <p>
            This role is designed for you to grow with us, and your efforts will be rewarded with a competitive commission structure. You will be well supported by our technical team both pre- and after-sales. Training will also be provided to help you understand the products and technology at a business level. 
        </p>
        <p>
            We would love to listen to your story and your ambition. If this opportunity resonates with you, please email your resume to <u>team@ai-directly.com</u>.
        </p>

        <h3>Software Sales Representative - Remote</h3>
        <p>
            We are looking for sales representatives who have burning desires to work in the artificial intelligence field. 
            We offer this opportunity to grow with us. Good commissions are paid. 
        </p>
        <p>
            You are expected to develop SMB accounts from finding leads to closing deals. You will be well supported by technology peers either pre- or after-sales. 
            Training will be provided for you to understand the products and technology at business level. At your side, 
            more sales experience is better and more technology background is better. We would love to listen to your story and your ambition.
        </p>
        <p>
            Please note: this position starts purely commission-based.
        </p>
        <p>
            If you are interested in it, please email your resume to team@ai-directly.com.
        </p>
    </section>
</template>

<script>
export default {
    name: 'CareerView',
};
</script>

<style scoped>
h2 {
    margin-top: 16px;
}

#career h3 {
    margin-top: 40px;
    color: #004b7a;
}

u {
    text-decoration: none;
    box-shadow: 0 1px 0 0 gray;
}
</style>