import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import CareerView from '@/views/CareerView.vue';
import ImmigView from '@/views/ImmigView.vue';
import CVbuildView from '@/views/CVbuildView.vue';
import BizPlanView from '@/views/BizPlanView.vue';
import ImmigForm from '@/views/ImmigForm.vue';
import CouponForm from '@/views/CouponForm.vue';
import LeadvalView from '@/views/LeadvalView.vue';
import WebsynthView from '@/views/WebsynthView.vue';
//import ReportView from '../views/ReportView.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/career',
    name: 'Career',
    component: CareerView
  },
  {
    path: '/immigrun',
    name: 'Immigration Consultant',
    component: ImmigView
  },
  {
    path: '/immigrun/partner',
    name: 'Immigration Partner',
    component: ImmigForm,
  },
  {
    path: '/immigrun/cvbuild',
    name: 'Resume Builder',
    component: CVbuildView,
  },
  {
    path: '/immigrun/bizplan',
    name: 'Business Planner',
    component: BizPlanView,
  },
  {
    path: '/immigrun/coupon',
    name: 'Welcome Coupons',
    component: CouponForm,
  },
  {
    path: '/leadval',
    name: 'Lead Validator',
    component: LeadvalView
  },
  {
    path: '/websynth',
    name: 'Web Synthesizer',
    component: WebsynthView
  },
  /*    {
      path: '/portal',
      name: 'Portal',
      component: ReportView
    }*/
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {     // 2024-7-26 to enable hash in routing
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth', top: 50 } // top:50 is optional: offset from the top in pixels
    } 

    return savedPosition || { top: 0 };
  }
});

export default router;
