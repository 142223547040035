<template>
    <section id="biz-plan" class="trunk">
        <pageCtrl v-model:lang="curLang" :page="'/immigrun/bizplan'" :cloak="cloak" />

        <iframeCompo :key="coid" coid="BizPlan" email_subject="Your business plan developed by AI-Directly Business Planning AI">
            <template #foreword>
                <h2>Business Planning AI</h2>
                <p>
                    BizPlan AI acts like a team of two assistants to streamline your business planning. One, an erudite mentor, guides you through complexities and unknowns. The other, a diligent scribe, transforms and elaborates the conversation insights into detailed chapters of your business plan.
                </p>
                <p>
                    It also drafts a pitch deck directly from the business plan, facilitating an easy transition from planning to presentation.
                </p>
                <div class="gpt-greet">
                    Let me begin by learning about your company background, products, services, market insights, and anything relevant.
                    <p>
                        Please paste the details or simply the source links, and I will retrieve the content from the websites if they are not anti-bot.
                    </p>
                </div>
            </template>

            <template #afterword>
                <p>
                    Once you're satisfied with any chapter of the business plan, you can request it to be sent to your email.
                </p>
                <!--p>
                    Please remain on this page while working on your business plan. Navigating away will drop the conversation content unless you are a paid user.
                </p-->
            </template>
        </iframeCompo>
    </section>
</template>

<script>
import { ref } from 'vue';
import iframeCompo from "../components/iframeCompo.vue";
import pageCtrl from "../components/pageCtrl.vue";

export default {
    name: 'BizPlanView',
    components: {
        iframeCompo,
        pageCtrl
    },
    setup() {
        // initialize the display language and whether the page be on post
        // The language display is pretty complicated
        // 1. Display in languages is controlled by variable curLang and v-if
        // 2. if the language is cn or tcn, the CSS will let the space between characters be wider through the class lang-cn
        // 3. if the language is far, the CSS wil let the font be larger through the class lang-far
        // 4. if the language is cn or tcn, labels and buttons will be shown in Chinese due to variable cnDisp and v-if, while cnDisp is set by @change script in <select>
        const queryParams = new URLSearchParams(window.location.search);
        const curLang = ref(queryParams.get('lang') || 'en');

        // The following is specifically for accommodating immigrun.com
        const cloak = (window.self !== window.top);

        //
        return { curLang, cloak };
    }
}
</script>

<style scoped>
</style>