export const requestPasscode = (coid, email) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email || !pattern.test(email)) {
        alert(`CMN-rP01: Invalid email address.`);
        return;
    }
        
    // disable the Email input first
    //document.getElementById('email-input')?.disabled = true;

    //
    fetch(`${process.env.VUE_APP_API_URL}/mfacode`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ coid, tag: email })
    })
    .then(response => {
        if (response.ok) {
            alert(`A passcode has been sent to your email box.\n\nIt expires in ${process.env.VUE_APP_PASSCODE_LIFECYCLE} minutes.`);
        } else {  
            alert(`CMN-rP02: ${response.status} - ${response.statusText}\n\nDetails: ${JSON.stringify(response.json())}`);
        }
    })
    .catch(error => {
        alert(`CMN-rP03: Request failed due to network issues. Please try again later.\n\n${error.message}`);
    });
};
